import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";

// Import videos
import {
	BRANDED_SERP_REPORT,
	CRUX_REPORT,
	FEATURED_SNIPPET_REPORT,
	LOG_MONITOR_REPORT,
	RANK_TRACKER_REPORT,
	TEMPLATE_CHECKER_REPORT,
	TRAFFIC_CHANGE_REPORT
} from "src/routesConstants";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Explore our Report Templates",
	subTitle: (
		<>
			<p>
				Once we&apos;ve got all your data loaded we help you get the
				most out of it.
			</p>
			<p>
				We&apos;ve anonymised a sample of our reports below so you can
				have a play around with them and see what is possible.
			</p>
			<i>
				This isn&apos;t all of our templates, but it&apos;s a good
				sample so you can see what we can do!
			</i>
		</>
	)
};

const alternatingContent = [
	{
		subTitle: "How did my traffic/revenue change?",
		content: (
			<React.Fragment>
				<p>
					Do you often get asked to explain why traffic has changed?
					Why is revenue up? Why are signups down?
				</p>
				<p>
					This report will help you find the sources quickly and
					easily.
				</p>
				<a
					role="button"
					className={`button buttonSecondary`}
					href={TRAFFIC_CHANGE_REPORT}
				>
					Explore the Traffic Change Report
				</a>
			</React.Fragment>
		)
	},
	{
		subTitle: "Do my website templates have technical SEO issues?",
		content: (
			<React.Fragment>
				<p>
					Crawling your whole site to find issues is relatively slow.
					We&apos;ll help you quickly find issues by checking all your
					templates for a representative sample of pages.
				</p>
				<p>
					By adding traffic data and intended values we&apos;ve got
					everything you need for a prioritised list of issues.
				</p>
				<a
					role="button"
					className={`button buttonSecondary`}
					href={TEMPLATE_CHECKER_REPORT}
				>
					Explore the Template Crawl Checker report.
				</a>
			</React.Fragment>
		)
	},
	{
		subTitle: "Is Google encountering issues crawling my site?",
		content: (
			<React.Fragment>
				<p>
					Do you need better insight into how Google is crawling your
					site and the errors it&apos;s encountering?
				</p>
				<p>
					We take your web logs and convert them into a report to help
					you dig into what&apos;s going on.
				</p>
				<a
					role="button"
					className={`button buttonSecondary`}
					href={LOG_MONITOR_REPORT}
				>
					Explore the Log File Analysis report.
				</a>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "How does my brand appear in Google?",
		content: (
			<React.Fragment>
				<p>
					Do you monitor your brand on Google? Rank 1 is pretty much a
					given, but what about the other results?
				</p>
				<p>
					We let you track all of your branded SERP and show you the
					opportunities to win more of it.
				</p>
				<a
					role="button"
					className={`button buttonSecondary`}
					href={BRANDED_SERP_REPORT}
				>
					Explore the Branded SERP monitor report.
				</a>
			</React.Fragment>
		),
		image: null
	},
	// {
	// 	subTitle: "How does my site rank in Google?",
	// 	content: (
	// 		<React.Fragment>
	// 			<p>
	// 				One of the most common sources we ingest is rank tracking
	// 				data. If you need a quick summary of how your site is
	// 				ranking then we&apos;ve got you covered.
	// 			</p>
	// 			<p>Explore rank, visibility, SERP features all in one place.</p>
	// 			<a
	// 				role="button"
	// 				className={`button buttonSecondary`}
	// 				href={RANK_TRACKER_REPORT}
	// 			>
	// 				Explore the Rank Tracking Report
	// 			</a>
	// 		</React.Fragment>
	// 	),
	// 	image: null
	// },
	{
		subTitle: "Which featured snippets can I win and how?",
		content: (
			<React.Fragment>
				<p>Which featured snippets can you win and how?</p>
				<p>
					We gather all the different information you need, from how
					often they appear to how volatile they and where you rank
					for them.
				</p>
				<p>Track optimise and win them.</p>
				<a
					role="button"
					className={`button buttonSecondary`}
					href={FEATURED_SNIPPET_REPORT}
				>
					Explore the Featured Snippet Report
				</a>
			</React.Fragment>
		),
		image: null
	}
	// {
	// 	subTitle: "Track your speed in CrUX",
	// 	content: (
	// 		<React.Fragment>
	// 			<p>Do you need to track overall site speed trends?</p>
	// 			<p>
	// 				We gather the CrUX data that Google provides for you and
	// 				your competitors and visualise it to make it easy to compare
	// 				and work with.
	// 			</p>
	// 			<a
	// 				role="button"
	// 				className={`button buttonSecondary`}
	// 				href={CRUX_REPORT}
	// 			>
	// 				Explore the CrUX Report
	// 			</a>
	// 		</React.Fragment>
	// 	),
	// 	image: null
	// }
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Set images
	alternatingContent[0].image = data.alternatingImg1.childImageSharp.fluid;
	alternatingContent[1].image = data.alternatingImg2.childImageSharp.fluid;
	alternatingContent[2].image = data.logMonitor.childImageSharp.fluid;
	alternatingContent[3].image = data.brandedSerp.childImageSharp.fluid;
	// alternatingContent[4].image = data.rankTracking.childImageSharp.fluid;
	alternatingContent[4].image = data.featuredSnippet.childImageSharp.fluid;
	// alternatingContent[6].image = data.crux.childImageSharp.fluid;

	const title = "Working with data in the warehouse";
	const description =
		"Now the data is loaded, we&apos;ve got huge amounts of data at our fingertips. We need working with it to be quick and painless, so we can get to problem solving and doing our jobs.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={false}
				isWhite={true}
			/>
			<section>
				{/* <StripTitle title="How does analysing data work?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-lg"
					alternate={false}
				/>
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query ReportsData {
		# Get us the main alternating images
		alternatingImg1: file(
			relativePath: {
				eq: "images/pages/reports/traffic_change_report.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1538, maxHeight: 1028) {
					...GatsbyImageSharpFluid
				}
			}
		}
		alternatingImg2: file(
			relativePath: {
				eq: "images/pages/reports/template_checker_report.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 2038, maxHeight: 1300) {
					...GatsbyImageSharpFluid
				}
			}
		}
		logMonitor: file(
			relativePath: { eq: "images/pages/reports/log_monitor.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 2038, maxHeight: 1300) {
					...GatsbyImageSharpFluid
				}
			}
		}
		featuredSnippet: file(
			relativePath: {
				eq: "images/pages/reports/featured_snippet_tracker.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 2038, maxHeight: 1300) {
					...GatsbyImageSharpFluid
				}
			}
		}
		brandedSerp: file(
			relativePath: {
				eq: "images/pages/reports/branded_serp_monitor.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 2038, maxHeight: 1300) {
					...GatsbyImageSharpFluid
				}
			}
		}
		crux: file(relativePath: { eq: "images/pages/reports/crux.png" }) {
			childImageSharp {
				fluid(maxWidth: 1431, maxHeight: 1134) {
					...GatsbyImageSharpFluid
				}
			}
		}
		rankTracking: file(
			relativePath: {
				eq: "images/pages/reports/rank_tracking_report.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 1515, maxHeight: 975) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
